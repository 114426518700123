#contact {
  background-color: #fcf9f3;
}
#contact .title-box {
  color: #25527c;
  text-align: center;
  padding-top: 8vh;
}
#contact .title-box h1 {
  color: #25527c;
  text-align: center;
  /* padding-top: 8vh; */
  font-size: 5vw;
  letter-spacing: 6px;
}
#contact .footer-row {
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
}
#contact .info h3 {
  color: #25527c;
}
#contact .footer-social {
  text-align: center;
  display: flex;
  color: #25527c;
  justify-content: space-evenly;
}
#contact .footer-social i {
  padding-right: 10px;
}
#contact .footer-row .fb {
  color: #3c5a99;
}
#contact .footer-row .youtube {
  color: #ff0000;
}
#contact .footer-row .phone {
  color: orange;
}
#contact .partner-logo img {
  height: 80px;
}
#contact .links {
  text-transform: capitalize;
}
#contact .partner-logo {
    display: flex;
    flex-flow: column;
    img{
      width: 64px;
    }
  }
