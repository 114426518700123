@import "navbar";
@import "banner";
@import "services";
@import "expertise";
@import "partners";
@import "contact";
@import "contacts";

body {
  font-family: "Baskervville", serif;
}

h1 {
  font-family: "Baskervville", serif;
}

.navbar {
  /* background-color: #0076ff; */
  /* background: linear-gradient(
    45deg,
    rgba(7, 241, 168, 1) 0%,
    rgba(20, 179, 245, 1) 20%,
    rgba(6, 91, 126, 1) 99%
  ); */
}

.home-nav {
  /* background-color: #0076ff; */
  /* background: transparent;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 50px; */
}

.home-nav:hover {
  /* background-color: #0076ff; */
  /* background: linear-gradient(
    45deg,
    rgba(7, 241, 168, 1) 0%,
    rgba(20, 179, 245, 1) 20%,
    rgba(6, 91, 126, 1) 99%
  ); */
}

.navbar .navbar-brand {
  /* color: white; */
  text-align: left;
  /* width: 100%; */
}

.navbar .navbar-brand:after {
  content: "ACEs International";
  font-size: 1.5rem;
}

.navbar .navbar-brand:hover:after {
  content: "Architecture Consulting and Engineering Services";
}

.navbar .nav-link {
  color: white;
}

.title-box {
  width: 100%;
}

.social-links a {
  font-size: 2.5rem;
  padding: 5px 10px;
  color: #25527c;
}

/*
expertise
*/

/*
services
*/

/*
projects
*/
#projects {
  /* position: absolute; */
  /* z-index: -1; */
  /* top: 0; */
  height: 80vh;
}

#projects .title {
  padding-top: 8vh;
  position: relative;
  z-index: 10;
  color: white;
  /* background-color: #00000055; */
  /* width: 60%; */
  display: flex;
  justify-content: center;
  font-size: 5rem;
  letter-spacing: 6px;
}

#projects .carousel-box {
  position: absolute;
  /* top: 0; */
  z-index: -1;
  width: 100%;
}

.carousel-item img {
  object-fit: cover;
  height: 80vh;
}

.carousel-item .carousel-caption {
  background-color: #00000055;
  /* width: 100%; */
}


/*
contacts
*/

.transition-timer-carousel-progress-bar {
  height: 6px;
  background-color: #5cb85c;
  width: 0%;
  margin: 0px 0px 0px 0px;
  border: none;
  z-index: 11;
  position: relative;
}

.article-row {
  display: flex;
  align-items: center;
}

.article-title-bg {
  /* background-color: #25527c; */
  /*min-height: 100vh;*/
}

.article-title-bg img {
  width: 100%;
  height: 20vh;
  padding-top: 20px;
}

.article-title-bg .article-title {
  color: #5cb85c;
  padding-top: 10px;
  font-size: 2rem;
  text-transform: capitalize;
}

.article-title-bg .text-box {
  padding: 10% 10%;
  text-align: justify;
  font-size: 18px;
  font-family: "Dosis", sans-serif;
}

.article-title-bg .text-box img {
  height: 264px;
  width: auto;
}

.project-folder-card .card-title {
  text-align: center;
}

.project-folder-img {
  width: 100%;
}

.single-page-main-image {
  width: 100%;
}

.article-content>p {
  font-size: 18px;
  font-family: "Dosis", sans-serif;
  text-align: justify;
}

.article-content ul li {
  font-size: 18px;
  font-family: "Dosis", sans-serif;
}

.article-content table tbody tr td {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
}

.article-content li {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
}

.article-content table tbody tr td:nth-child(2) {
  padding-left: 15px;
}

.tab-content {
  padding: 16px;
}
