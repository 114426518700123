#services {
  position: relative;
  .background {
    color: #25527c;
    background-size: cover;
    opacity: 0.4;
    filter: blur(1.5px);
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;

  }
  .card-overlay {
    background-color: #93ddfd99;
    height: inherit;
    position: relative;
    z-index: 2;
    top: 0;
    padding-bottom: 30px;
  }
  .row {
    width: 100%;
  }
  .title-box {
    padding-top: 8vh;
    padding-bottom: 5vh;
  }
  h1 {
    color: #25527c;
    font-size: 5vw;
    letter-spacing: 6px;
  }
  .card-columns {
    column-count: 4;
    padding: 10px 30px 30px 5px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .card {
    border: none;
    padding: 50px 5px 40px 5px;
    margin-bottom: 50px;
    .icon-area {
      width: 100%;
      text-align: center;
    }
    i {
      color: #25527c;
      font-size: 6rem;
    }
    img {
      width: 150px;
      height: 150px;
    }
  }
  .card-block {
    background: transparent;
    .card-title {
      color: #25527c;
      text-align: center;
    }
  }
  .card-footer {
    background: transparent;
    text-align: center;
    border: none;
    font-family: "Dosis", sans-serif;
    font-weight: 800;
  }
}
@media only screen and (min-width: 720px) {
  #services {
    .cards-area{
      display: flex;

      .card{
        width: 25vw;
      }
    }


  }
}
