.navbar:hover {
  background-color: white;

  .navbar-nav {
    .nav-item {


      // padding-left: 10px;
      a {
        color: #25527c;
      }
    }

    .active {
      // background-color: #25527c;

      a {
        color: #25527c;
      }


    }
  }
}

.navbar {
  background-color: transparent;
  height: 86px;

  .navbar-nav {
    .nav-item {

      // padding-left: 10px;
      a {
        color: #25527c;
        font-weight: bold;
        font-size: 1.2rem;
      }

      a:hover {
        font-style: italic;
      }
    }

    .active {
      // background-color: #25527c;
      border-bottom: 1px #25527c solid;

      .nav-link {
        color: #25527c;
      }
    }
  }
}


.navbar.scrolled {
  background-color: white;

  .navbar-nav {
    .nav-item {

      // padding-left: 10px;
      a {
        color: #25527c;
      }
    }

    .active {
      // background-color: #25527c;

      .nav-link {
        color: #25527c;
      }
    }
  }
}
