#expertise {
  color: #25527c;
  // height: 100vh;
  .row {
    width: 100%;
  }
  .section-box{
    height: inherit;
    .box{
      height: inherit;
      .row{
        height: 20%;
      }
      .cards-area{
        height: 80%;
      }
    }
  }
  .title-box {
    // padding-top: 8vh;
    // padding-bottom: 5vh;
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  h1 {
    color: #25527c;
    letter-spacing: 6px;
    font-size: 5vw;
  }
  .card-columns {
    column-count: 4;
    padding: 10px 30px 30px 5px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .card {
    border: none;
    padding: 25px 15px;
    flex-basis: 0;
    flex-grow: 1;
    // width: 250px;
    .icon-area {
      width: 100%;
      text-align: center;
      img {
        /* color: #25527c;
        font-size: 6rem; */
        width: 100%;
        height: 150px;
        padding-bottom: 10px;
      }
    }
  }
  .card-block {
    background: transparent;
    .card-title {
      color: #25527c;
      text-align: center;
      font-size: 1.2em;
    }
  }
  .card-footer {
    background: transparent;
    text-align: center;
    border: none;
    font-family: "Dosis", sans-serif;
    font-weight: 800;
  }
}
@media only screen and (min-width: 720px) {
  #expertise {
    height: 80vh;
    .box{
      padding-top: 5vh;
      .row{
        display: flex;
        align-items: center;
      }
      .cards-area{
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }
  }
}
