/*
partners
*/
#partners {
  color: #25527c;
  padding-bottom: 45px;
  // height: 60vh;
  .primary-overlay{
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

#partners .row {
  width: 100%;
}
#partners .title-box {
  // padding-top: 10vh;
}
#partners h1 {
  color: #25527c;
  letter-spacing: 6px;
  font-size: 5vw;
}
#partners .card-columns {
  column-count: 4;
  padding: 10px 30px 30px 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}
#partners .card {
  border: none;
  /* padding: 0 15px; */
  // width: 350px;
  padding: 50px 5px 10px 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
#partners .card .icon-area img {
  height: 124px;
  padding-bottom: 20px;
}
#partners .card-block .card-title,
.card-subtitle {
  color: #25527c;
  text-align: center;
  font-size: 14px;
}
#partners .card-footer {
  background: transparent;
  text-align: center;
  border: none;
}
@media only screen and (min-width: 720px) {
  #partners {
    .cards-area{
      display: flex;
      justify-content: center;

      .card{
        width: 25vw;
      }
    }


  }
}
