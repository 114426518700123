
.social-box{
  display: flex;
  flex-flow: column;
  padding-top: 30px;

}
#contact{
  h4{
    color: gray;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h5{
    font-size: 14px;
  }
  .credit_icons{
    font-size: x-small;
    color: #6c757d;
    font-family: cursive;
  }
  svg{
    width: 30px;
  }
}
