#banner {
  background-image: url("/images/wall.jpg");
  // height: 100vh;
  width: 100%;
  background-size: cover;
  margin-top: -86px;

  .section-area {
    height: inherit;
  }

  .slogan-box {
    width: 100%;
    // padding-top: 15vh;
    letter-spacing: 1rem;

    .slogan {
      font-size: 5vw;
    }
  }
}

@media only screen and (min-width: 720px) {
  #banner {
    height: 100vh;

    .container {
      height: inherit;

      .row {
        height: inherit;

        .slogan-box {
          height: inherit;
          display: flex;
          flex-flow: column;
          justify-content: center;
        }
      }
    }

    // .box{
    //   padding-top: 5vh;
    //   .row{
    //     display: flex;
    //     align-items: center;
    //   }
    //   .cards-area{
    //     display: flex;
    //     align-items: center;
    //   }
    //
    // }
  }
}
